import { For, Switch, Match, Show, Suspense } from 'solid-js';
import { FieldDescription, Heading, Label, RadioBar, RadioBarButton, RadioGroup } from '@troon/ui';
import { FrequentlyAskedQuestions } from '../faqs';

type Props = {
	faqs?: string;
	minPlayers: number;
	maxPlayers: number;
	players: number;
	rateName?: string;
	setPlayers: (count: number) => void;
};

export function PlayersSelect(props: Props) {
	return (
		<RadioGroup name="__players" onSelect={(index) => props.setPlayers(parseInt(index, 10) + 1)}>
			<Label class="sr-only">Number of Players</Label>
			<RadioBar>
				<For each={new Array(4).fill(0)}>
					{(info, index) => (
						<RadioBarButton
							value={index()}
							checked={props.players === index() + 1}
							disabled={props.minPlayers > index() + 1 || props.maxPlayers <= index()}
						>
							<Label>{index() + 1}</Label>
						</RadioBarButton>
					)}
				</For>
			</RadioBar>

			<Suspense>
				<FieldDescription>
					<div class="flex flex-col gap-4">
						<Switch>
							<Match when={(props.minPlayers ?? 0) > 1}>
								<p>
									The {props.rateName} rate requires a minimum of {props.minPlayers} players to book this tee time.
								</p>
							</Match>
							<Match when={(props.maxPlayers ?? 0) > 1}>
								<p>There are {props.maxPlayers ?? 0} spots available for this tee time.</p>
							</Match>
							<Match when={(props.maxPlayers ?? 0) === 1}>
								<p>There is only 1 spot available for this tee time.</p>
							</Match>
						</Switch>
						<Suspense>
							<Show when={props.faqs}>
								{(faqs) => (
									<div>
										<Heading as="h2" class="text-sm uppercase text-neutral-700 md:text-sm">
											FAQs
										</Heading>
										<FrequentlyAskedQuestions layout="tight" fullWidth id={faqs()} />
									</div>
								)}
							</Show>
						</Suspense>
					</div>
				</FieldDescription>
			</Suspense>
		</RadioGroup>
	);
}
