import { IconEditPencil01 } from '@troon/icons/edit-pencil-01';
import { Button, Section, SectionStep } from '@troon/ui';
import { createEffect, createSignal } from 'solid-js';
import type { ComponentProps } from 'solid-js';

type Props = Omit<ComponentProps<typeof SectionStep>, 'action' | 'appearance'> & {
	onEdit?: () => void;
};

export function CheckoutStep(props: Props) {
	const [ref, setRef] = createSignal<HTMLElement>();

	// Scroll the step into view when current, if it's bottom is out of view
	createEffect(() => {
		const el = ref();
		if (el && props.state === 'current') {
			const bounds = el.getBoundingClientRect();
			if (bounds.top > window.scrollY || bounds.bottom < window.innerHeight + window.scrollY) {
				el.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' });
			}
		}
	});

	return (
		<Section
			appearance="contained"
			ref={setRef}
			aria-current={props.state === 'current' ? 'step' : 'false'}
			class="aria-current-step:ring-1 aria-current-step:ring-brand-300"
		>
			<SectionStep
				state={props.state}
				step={props.step}
				action={
					props.state === 'completed' && props.onEdit ? (
						<Button appearance="transparent" onClick={props.onEdit} class="size-fit grow-0" size="sm">
							<IconEditPencil01 />
							<span class="sr-only">Edit this step</span>
						</Button>
					) : null
				}
				title={props.title}
				value={props.value}
			>
				{props.children}
			</SectionStep>
		</Section>
	);
}
