import { createEffect, createSignal, For, Show, Suspense } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { ActivityIndicator, Button, Form, Label, Radio, RadioGroup } from '@troon/ui';
import { createAsync, useSubmission } from '@solidjs/router';
import { useTrackEvent } from '@troon/analytics';
import { cardBrandToComponent, cardBrandToString } from '../../modules/credit-cards';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { addCard, AddCardFields, addCardMutation } from '../../partials/add-card';
import { useUser } from '../../providers/user';
import type { CreditCard } from '../../graphql';

export function SelectedCreditCard(props: { card?: CreditCard }) {
	return (
		<Show when={props.card}>
			{(card) => (
				<div class="flex items-center gap-2">
					<Dynamic component={cardBrandToComponent[card().brand]!} class="w-10" /> {cardBrandToString[card().brand]}{' '}
					ending in {card().lastFour}.
				</div>
			)}
		</Show>
	);
}

type SelectProps = {
	onComplete: (card: CreditCard) => void;
};

export function SelectCreditCard(props: SelectProps) {
	const user = useUser();
	const ccInfo = createAsync(async () => (user() ? getCreditCards({}) : null), { deferStream: false });
	const trackEvent = useTrackEvent();
	const [cardId, setCardId] = createSignal<string>();
	const addCardAction = addCard(trackEvent, setCardId);
	const [showAddCardForm, setShowAddCardForm] = createSignal(false);
	const addCardSubmission = useSubmission(addCardAction);

	createEffect(() => {
		if (addCardSubmission.result?.data) {
			setCardId(addCardSubmission.result.data.addCreditCard.id);
			props.onComplete(addCardSubmission.result.data.addCreditCard);
			addCardSubmission.clear();
		}
	});

	createEffect(() => {
		const firstCard = ccInfo()?.creditCards[0];
		if (!cardId() && firstCard) {
			setCardId(firstCard.id);
		}
	});

	return (
		<Suspense fallback={<ActivityIndicator />}>
			<p class="text-neutral-700">
				This course requires a credit card to book a tee time. You will only be charged if you no show or cancel beyond
				the cancellation policy.
			</p>
			<Show
				when={(ccInfo()?.creditCards ?? []).length > 0 && !showAddCardForm()}
				fallback={
					<Form action={addCardAction} document={addCardMutation}>
						<AddCardFields />
						<div class="flex gap-8">
							<Show when={(ccInfo()?.creditCards ?? []).length > 0}>
								<Button
									disabled={addCardSubmission.pending}
									appearance="tertiary-current"
									type="button"
									onClick={() => setShowAddCardForm(false)}
								>
									Cancel
								</Button>
							</Show>
							<Button type="submit">Add Card & Continue</Button>
						</div>
					</Form>
				}
			>
				<RadioGroup name="creditCardId" onSelect={setCardId}>
					<Label class="sr-only">Payment Method</Label>
					<div class="flex flex-col gap-2 rounded py-2">
						<For each={ccInfo()?.creditCards ?? []}>
							{(card, i) => (
								<div
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class="cursor-pointer rounded border border-neutral bg-white px-3 py-2 has-[:checked]:border-brand has-[:checked]:bg-brand-100 md:px-4 md:py-2"
									onClick={() => setCardId(card.id)}
								>
									<Radio value={card.id} checked={(!cardId() && i() === 0) || cardId() === card.id}>
										<Label class="flex flex-row items-center gap-2">
											<div class="w-10">
												<Dynamic component={cardBrandToComponent[card.brand]} />
											</div>
											<div class="grow">
												{cardBrandToString[card.brand]} ending in {card.lastFour}
											</div>
										</Label>
									</Radio>
								</div>
							)}
						</For>
						<Button
							appearance="transparent-current"
							class="size-fit justify-start text-start font-normal normal-case"
							onClick={() => setShowAddCardForm(true)}
							type="button"
							size="sm"
						>
							+ Add Payment Method
						</Button>
					</div>
				</RadioGroup>

				<Button
					type="button"
					disabled={!cardId()}
					onClick={() => {
						if (cardId()) {
							const card = ccInfo()!.creditCards.find((cc) => cc.id === cardId());
							if (card) {
								props.onComplete(card);
							}
						}
					}}
				>
					Select & Continue
				</Button>
			</Show>
		</Suspense>
	);
}

const cardQuery = gql(`
query paymentMethods {
	creditCards {
		id
		lastFour
		brand
	}
}`);

const getCreditCards = cachedQuery(cardQuery);
