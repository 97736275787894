import { Index, splitProps, useContext, Show, Match, Switch, createSignal, createMemo, createEffect } from 'solid-js';
import {
	Button,
	Dialog,
	DialogContent,
	Field,
	FieldDescription,
	Label,
	RadioGroup,
	RadioGroupContext,
	RadioInternal,
	Tag,
} from '@troon/ui';
import { IconCircleCheck } from '@troon/icons/circle-check';
import { twJoin } from '@troon/tailwind-preset/merge';
import { useTrackEvent } from '@troon/analytics';
import { createFragment } from '../../graphql/create-fragment';
import { gql, TroonCardSubscriptionProductType } from '../../graphql';
import { createDollarFormatter } from '../../modules/number-formatting';
import { useUser } from '../../providers/user';
import { AccessTeeTimeRateUpsell } from '../upsells/access-tee-time';
import { AuthFlow } from '../../partials/auth/auth';
import type { ComponentProps } from 'solid-js';
import type { FragmentType, RateSelectionFragment } from '../../graphql';

type Props = {
	onSelect: (rateId: string) => void;
	rates: Array<FragmentType<typeof RateFragment>> | undefined;
	selected?: string | null;
	userHasAccess: boolean;
};

export function RateSelect(props: Props) {
	const rates = createFragment(RateFragment, props, 'rates');
	const user = useUser();
	const [dialogOpen, setDialogOpen] = createSignal(false);
	const selectedRate = createMemo(() => rates?.find((rate) => rate.id === props.selected));
	const [showLogin, setShowLogin] = createSignal(false);
	const trackEvent = useTrackEvent();

	const publicRate = createMemo(() => rates?.find((rate) => rate.isPublicRate));

	// HACK
	// The backend potentially returns a completely different rate ID when logging in
	// So we need to store whether the rate selected was TA/TA+ and re-select a "close enough" match
	const [selectedAccessRate, setSelectedAccessRate] = createSignal(
		// eslint-disable-next-line solid/reactivity
		selectedRate()?.isTroonAccessPlusRate || selectedRate()?.isTroonAccessRate,
	);
	// If TA rate was selected and the rate is no longer in the rates list
	// find the next best match
	createEffect(() => {
		if (selectedAccessRate() && rates && rates.length && !rates.find((rate) => rate.id === props.selected)) {
			const troonAccessRate = rates.find((rate) => rate.isTroonAccessRate || rate.isTroonAccessPlusRate);
			if (troonAccessRate) {
				props.onSelect(troonAccessRate.id);
			}
		}
	});

	const rateNeedsUpsell = createMemo(() => {
		const isTroonAccessRate = selectedRate()?.isTroonAccessRate || selectedRate()?.isTroonAccessPlusRate;
		setSelectedAccessRate(isTroonAccessRate);
		return isTroonAccessRate && !props.userHasAccess;
	});

	createEffect(() => {
		if (rateNeedsUpsell()) {
			setDialogOpen(true);
		}
	});

	createEffect(() => {
		if (dialogOpen()) {
			trackEvent('dialogOpened', { key: 'troon-access-rate-upsell' });
		}
	});

	createEffect(() => {
		if (!dialogOpen() && rateNeedsUpsell()) {
			const pub = publicRate();
			if (pub) {
				props.onSelect(pub.id);
			}
		}

		if (dialogOpen() && !rateNeedsUpsell()) {
			setDialogOpen(false);
		}
	});

	return (
		<>
			<RadioGroup name="rate" required class="gap-4">
				<Label class="sr-only">Choose your rate</Label>
				<Show when={rates?.length}>
					<Index each={rates || []}>
						{(rate) => (
							<Rate
								onChange={(e) => {
									if (e.target.checked) {
										props.onSelect(rate().id);
									}
								}}
								rate={rate()}
								value={rate().id}
								publicPrice={publicRate()?.price || rate().price}
								checked={props.selected === rate().id}
							/>
						)}
					</Index>
				</Show>
			</RadioGroup>
			<Dialog
				key="troon-access-rate-upsell"
				open={dialogOpen()}
				onOpenChange={(open) => {
					setDialogOpen(open);
					if (!open) {
						trackEvent('dialogClosed', { key: 'troon-access-rate-upsell' });
						setShowLogin(false);
					}
				}}
			>
				<DialogContent
					noPadding
					height="fit"
					width={showLogin() ? 'lg' : undefined}
					closeButton={showLogin() ? 'text-white' : undefined}
				>
					<Switch>
						<Match when={showLogin()}>
							<AuthFlow onComplete={() => setDialogOpen(false)} skipUrlUpdate />
						</Match>
						<Match when={selectedRate()}>
							{(rate) => (
								<AccessTeeTimeRateUpsell
									type={
										rate().isTroonAccessPlusRate
											? TroonCardSubscriptionProductType.TroonAccessPlus
											: TroonCardSubscriptionProductType.TroonAccess
									}
									publicRate={publicRate()?.price || rate().price}
									rate={rate().price}
								>
									<Show when={!user()?.me}>
										<Button appearance="transparent" class="font-normal normal-case" onClick={() => setShowLogin(true)}>
											Already have Troon Access? Log in
										</Button>
									</Show>
								</AccessTeeTimeRateUpsell>
							)}
						</Match>
					</Switch>
				</DialogContent>
			</Dialog>
		</>
	);
}

type RateProps = Omit<ComponentProps<typeof RadioInternal>, 'name'> & {
	rate: RateSelectionFragment;
	publicPrice: { displayValue: string; value: string };
};

function Rate(props: RateProps) {
	const user = useUser();
	const ctx = useContext(RadioGroupContext);
	if (!ctx.name) {
		throw new Error('Radio element must be within a RadioGroup');
	}
	const [, rest] = splitProps(props, ['rate']);
	const dollarFormatter = createDollarFormatter();

	return (
		<Field
			name={ctx.name}
			class="relative flex cursor-pointer items-center justify-between gap-4 rounded border border-neutral p-4 pe-0 transition-colors hover:border-brand hover:bg-brand-100 has-[:checked]:border-brand has-[:checked]:bg-brand-100 md:p-6 md:pe-0"
		>
			<RadioInternal
				{...rest}
				name={ctx.name}
				// !Important: size-full fixes Mobile Safari to ensure this fits the entire parent
				class="peer absolute inset-0 size-full cursor-pointer appearance-none outline-none"
			/>
			<div class="flex grow flex-col">
				<Label>{props.rate.name}</Label>
				<Switch>
					<Match when={props.rate.description}>
						<FieldDescription>{props.rate.description}</FieldDescription>
					</Match>
					<Match when={props.rate.isPublicRate}>
						<FieldDescription>Standard rate</FieldDescription>
					</Match>
					<Match
						when={
							(props.rate.isTroonAccessRate || props.rate.isTroonAccessPlusRate) && user()?.activeTroonCardSubscription
						}
					>
						<FieldDescription>
							Save {dollarFormatter().format(parseFloat(props.publicPrice.value) - props.rate.price.value)} with your{' '}
							{props.rate.name} membership
						</FieldDescription>
					</Match>
					<Match when={props.rate.isTroonAccessRate || props.rate.isTroonAccessPlusRate}>
						<FieldDescription>
							Save {dollarFormatter().format(parseFloat(props.publicPrice.value) - props.rate.price.value)} when you
							join {props.rate.name}
						</FieldDescription>
					</Match>
				</Switch>
			</div>
			<div class="flex grow-0 flex-col items-end">
				<span
					class={twJoin(
						'flex gap-2 text-lg font-semibold',
						(props.rate.isTroonAccessRate || props.rate.isTroonAccessPlusRate) && 'text-brand-600',
					)}
				>
					<Show when={props.rate.isTroonAccessRate || props.rate.isTroonAccessPlusRate}>
						<Tag appearance="access" rounded class="whitespace-nowrap">
							{Math.floor(
								(100 * (parseFloat(props.publicPrice.value) - parseFloat(props.rate.price.value))) /
									parseFloat(props.publicPrice.value),
							)}
							% off
						</Tag>
					</Show>
					{props.rate.price.displayValue}
				</span>
				<Show when={props.rate.isTroonAccessRate || props.rate.isTroonAccessPlusRate}>
					<span class="text-neutral-800 line-through">{props.publicPrice.displayValue}</span>
				</Show>
			</div>
			<IconCircleCheck class="me-1 size-8 w-0 shrink-0 scale-0 text-brand transition-all peer-checked:me-4 peer-checked:w-auto peer-checked:scale-100 md:me-2 md:peer-checked:me-6" />
		</Field>
	);
}

export const RateFragment = gql(`fragment RateSelection on CourseTeeTimeRate {
	id
	name
	description
	isAvailableToUser
	isLocalCardRate
	isPublicRate
	isTroonAccessRate
	isTroonAccessPlusRate
	price { displayValue, value }
}`);
