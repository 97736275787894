import { Show, Suspense } from 'solid-js';
import { HorizontalRule, Link, Picture } from '@troon/ui';
import { IconFlag } from '@troon/icons/flag';
import { IconGolfCart } from '@troon/icons/golf-cart';
import { IconUsers } from '@troon/icons/users';
import { gql } from '../../graphql';
import { dayTimeToDate, formatDateTime } from '../../modules/date-formatting';
import { createNumberFormatter, holesFormatter } from '../../modules/number-formatting';
import { createFragment } from '../../graphql/create-fragment';
import type { FragmentType, TeeTimeInfoFragment } from '../../graphql';

type HeaderProps = {
	info: FragmentType<typeof TeeTimeInfoFragment> | undefined;
	facilityName: string;
	facilitySlug: string;
	facilityLogo?: string | null;
	showInfo?: boolean;
};

export function TeeTimeInfoHeader(props: HeaderProps) {
	const numberFormatter = createNumberFormatter();
	const info = createFragment(TeeTimeInfoFragment, props, 'info');

	return (
		<div class="flex flex-col gap-4">
			<div class="relative flex items-center justify-between gap-8">
				<div class="flex grow flex-col">
					<p class="text-lg font-semibold">
						<Link
							href={`/course/${props.facilitySlug}/reserve-tee-time`}
							class="after:absolute after:inset-0 hover:text-brand hover:underline"
						>
							{props.facilityName}
						</Link>
					</p>
					<p class="text-neutral-700">
						<Suspense fallback={<Skeleton width={8} />}>{info?.course?.name}</Suspense>
					</p>
					<p class="text-neutral-700">
						<Suspense fallback={<Skeleton width={14} />}>
							<Show when={info?.rate?.dayTime}>{(dayTime) => formatDateTime(dayTimeToDate(dayTime()))}</Show>
						</Suspense>
					</p>
				</div>

				<div class="shrink-0 overflow-hidden rounded border border-neutral p-2">
					<Picture
						preload
						src={props.facilityLogo}
						width={200}
						height={200}
						sizes="5rem"
						mode="contain"
						class="w-16 rounded object-cover md:w-20"
					/>
				</div>
			</div>

			<Show when={props.showInfo}>
				<HorizontalRule />
				<ul class="flex flex-row flex-wrap gap-4 text-sm">
					<li class="flex items-center gap-1">
						<IconFlag class="size-6 text-brand" />
						<span>
							<Suspense fallback={<Skeleton width={3} />}>
								<Show when={info?.holes}>{(holes) => holesFormatter(numberFormatter(), holes())}</Show> holes
							</Suspense>
						</span>
					</li>
					<li class="flex items-center gap-1">
						<IconUsers class="size-6 text-brand" />
						<span>
							<Suspense fallback={<Skeleton width={1} />}>{info?.players}</Suspense>
						</span>
					</li>
					<Suspense>
						<Show when={info?.rate?.cartIncluded}>
							<li class="flex items-center gap-1">
								<IconGolfCart class="size-6 text-brand" />
								<span>Cart included</span>
							</li>
						</Show>
					</Suspense>
				</ul>
			</Show>
		</div>
	);
}

function Skeleton(props: { width: number }) {
	return (
		<div class="animate-pulse rounded-sm bg-neutral-400" style={{ width: `${props.width}rem` }}>
			&nbsp;
		</div>
	);
}

const TeeTimeInfoFragment = gql(`fragment TeeTimeInfo on CourseTeeTimeReservationInfo {
	course { name }
	players
	holes
	rate {
		cartIncluded
		dayTime { day { year, month, day }, time { hour, minute } }
	}
}`);
