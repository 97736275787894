import { IconLogoSquare } from '@troon/icons/logo-square';
import { Show } from 'solid-js';

type Props = {
	rewardPointsEarned?: number;
};

export function RewardsPointsEarned(props: Props) {
	return (
		<Show when={props.rewardPointsEarned}>
			{(pointsEarned) => (
				<p class="flex items-center gap-4 rounded bg-brand-100 p-4 text-sm text-brand-600">
					<IconLogoSquare class="size-8 shrink-0 text-brand" />
					<span>
						Earn up to <b class="font-bold">{pointsEarned()}</b> <b class="font-bold">Troon Rewards</b> points when you
						pay for your round.
					</span>
				</p>
			)}
		</Show>
	);
}
